@layer tailwind-base, primereact, tailwind-utilities;

/* IMPORTANT: In "styled" mode you must add the PrimeReact Theme here. Do NOT include in "unstyled" mode */
/* @import 'primereact/resources/themes/lara-light-blue/theme.css'; */

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer base {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    /* -webkit-text-fill-color: rgba(148, 163, 184, 1); */
    /* -webkit-box-shadow: 0 0 0 30px #0b0d28 inset !important; */
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  }

  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    /* -webkit-text-fill-color: rgba(148, 163, 184, 1); */
    /* -webkit-box-shadow: 0 0 0 30px #0b0d28 inset !important; */
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  }
}

@layer base {
  *:not(.geScrollable)::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  * ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  * ::-webkit-scrollbar-thumb {
    background-color: rgba(74, 222, 128, 0.2);
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 10px;
  }

  * ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(74, 222, 128, 0.5);
  }

  *::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px;
  }
}
